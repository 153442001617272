<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-form ref="form" v-model="valid" lazy-validation height="100%">
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("tests.template")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.templateName"
                  :rules="[rules.required]"
                  :label="$t('tests.templateName')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <span class="pa-1 ">
                  {{ $t("tests.templateContent") }}
                </span>

                <Quill
                  :prop.sync="editedItem.templateContent"
                  :myContent="editedItem.templateContent"
                ></Quill>

                <!-- <tiptap-vuetify
                  class="mt-1"
                  v-model="editedItem.templateContent"
                  :label="$t('tests.templateContent')"
                  :extensions="extensions"
                /> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// import {
//   TiptapVuetify,
//   Heading,
//   Bold,
//   Italic,
//   Strike,
//   Underline,
//   Code,
//   Paragraph,
//   BulletList,
//   OrderedList,
//   ListItem,
//   // Link,
//   Blockquote,
//   HardBreak,
//   HorizontalRule,
//   History,
// } from "tiptap-vuetify";
// import TextAlign from "@tiptap/core";
import Quill from "../../../components/Quill.vue";

export default {
  components: {
    Quill,
    //, TiptapVuetify
  },
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
  ],
  data() {
    return {
      // extensions: [
      //   History,
      //   Blockquote,
      //   // Link,
      //   Underline,
      //   Strike,
      //   Italic,
      //   ListItem,
      //   BulletList,
      //   OrderedList,
      //   [
      //     Heading,
      //     {
      //       options: {
      //         levels: [1, 2, 3],
      //       },
      //     },
      //   ],
      //   Bold,
      //   Code,
      //   HorizontalRule,
      //   Paragraph,
      //   HardBreak,
      //   // TextAlign,
      // ],
      valid: true,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          this.$axios
            .put(
              "TestTemplate/Update?guid=" + this.editedItem.guid,
              this.editedItem
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        } else {
          this.$axios
            .post("TestTemplate/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
