<template>
  <v-container fluid>
    <v-card outlined class="pa-5 mt-3">
      <v-row>
        <v-col cols="auto">
          <v-radio-group hide-details="" dense v-model="particularType" row>
            <v-radio
              v-for="type in particularTypes"
              :key="type.value"
              :label="type.text"
              :value="type.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-autocomplete
            class="mt-2"
            outlined
            v-model="particularGuid"
            :label="$t('tests.particularName')"
            :items="testParticular"
            item-text="text"
            item-value="value"
            dense
            hide-details=""
          ></v-autocomplete
        ></v-col>
      </v-row>

      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(76)"
          color="primary"
          elevation="0"
          class="ma-1"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="testTemplates"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      particularType: null,
      particularGuid: "",
      testTemplates: [],
      testParticular: [],
      editedItem: { particularType: 0 },
      defaultItem: { particularType: 0 },
      resetValidation: 0,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  computed: {
    particularTypes() {
      var list = [];
      if (this.$store.getters.isInRole(106))
        list.push({
          text: this.$t("tests.particularTypes.Pathology"),
          value: 0,
        });
      if (this.$store.getters.isInRole(107))
        list.push({
          text: this.$t("tests.particularTypes.Radiology"),
          value: 1,
        });
      if (this.$store.getters.isInRole(108))
        list.push({
          text: this.$t("tests.particularTypes.Histopathology"),
          value: 2,
        });
      if (this.$store.getters.isInRole(109))
        list.push({
          text: this.$t("tests.particularTypes.Audiology"),
          value: 3,
        });

      return list;

      // return [
      //   { text: this.$t("tests.particularTypes.Pathology"), value: 0 },
      //   { text: this.$t("tests.particularTypes.Radiology"), value: 1 },
      //   { text: this.$t("tests.particularTypes.Histopathology"), value: 2 },
      //   { text: this.$t("tests.particularTypes.Audiology"), value: 3 },
      // ];
    },
    headers() {
      var list = [
        { text: this.$t("tests.templateName"), value: "templateName" },
        // { text: this.$t("tests.templateContent"), value: "templateContent" },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(77)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(78)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();

    if (this.particularTypes.length >= 0) {
      this.particularType = this.particularTypes[0].value;
      this.getParticular();
    }
  },

  watch: {
    dialog(val) {
      if (this.particularGuid) {
        this.editedItem.testParticularGuid = this.particularGuid;
        val || this.close();
      } else {
        this.dialog = false;
        if (!val) {
          this.$toast.error(this.$t("tests.selectParticularFirst"));
        }
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    particularType() {
      this.testTemplates = [];
      this.particularGuid = null;
      this.getParticular();
    },
    particularGuid() {
      this.refreshTable();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.testTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.testTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.testTemplates[this.editedIndex];
      this.$axios
        .delete("TestTemplate/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      if (this.particularGuid) {
        this.$axios
          .get("TestTemplate?particularGuid=" + this.particularGuid)
          .then((response) => {
            this.testTemplates = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getParticular() {
      this.testParticular = [];
      if (this.particularType >= 0) {
        this.$axios
          .get("TestParticular?type=" + this.particularType)
          .then((response) => {
            for (let index = 0; index < response.data.data.length; index++) {
              var element = response.data.data[index];
              this.testParticular.push({
                text: element.particularName,
                value: element.guid,
              });
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
